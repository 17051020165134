import React from 'react';
import { useState, useEffect } from 'react';
import Banners from '../../Components/Banners/Banners';
import Stores from '../../Components/Stores/Stores';
import Products from '../../Components/Products/Products';
import './Demo.css';
import Footer from '../../Components/Footer/Footer';
import Spiner from '../../Components/Admin/Spiner/Spiner';
import baseURL from '../../Components/url';
//import BtnWhatsapp from '../../Components/BtnWhatsapp/BtnWhatsapp';


export default function Demo() {

    const [loading, setLoading] = useState(true);

    useEffect(() => {

        setLoading(true);

        setTimeout(() => {

            setLoading(false); 
            
        }, 1500);

    }, []);

    return (
        <section className='demo'>
            {
                loading ? (
                    <Spiner />
                ) : 
                <>
                    <Banners />
                    {/* <Products /> */}
                    <Stores />
                    <Footer />
                    { /* <BtnWhatsapp /> */ }
                    { /* <BtnContactWhatsapp /> */ }
                </>
            }
        </section>
    )
}
