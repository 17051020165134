import React, { useEffect, useState } from 'react';
import {fab, faAndroid, faAppStoreIos, faChrome, faWpexplorer } from "@fortawesome/free-brands-svg-icons";
import { faUser, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './ButonInstallAppNav.css';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router';

export default function ButonInstallAppNav() {

    const { id } = useParams();

    const navigate = useNavigate();
    
    const [supportsPWA, setSupportsPWA] = useState(false);

    const [installedPWA, setInstalledPWA] = useState(true);

    const [promptInstall, setPromptInstall] = useState(null);

    const [showinPWA, setShowinPWA] = useState(false);
    
    const [isStandalone, setIsStandalone] = useState(false);

    useEffect(() => {

        const handler = e => {

            e.preventDefault();

            console.log('beforeinstallprompt event fired');

            console.log(e);
            
            setSupportsPWA(true);

            setInstalledPWA(false);
            
            setPromptInstall(e);

        };

        window.addEventListener("beforeinstallprompt", handler);

        //window.addEventListener('load', checkPWAStatus);

        window.addEventListener('appinstalled', () => {

            console.log('La PWA ha sido instalada en el dispositivo');

        });

        if(id && id.length > 0) {

            console.log(`This id is ${id}`);

            checkPWAStatus();

        }

        return () => window.removeEventListener("transitionend", handler);
        
    }, []);

    const checkPWAStatus = () => {

        console.log('Comprobando el estado de la PWA...');

        const userAgentData = window.navigator.userAgentData;
        const isMobileDevice = userAgentData && userAgentData.mobile;
        const isAndroid = userAgentData && userAgentData.platform === 'Android';
        const isiOS = userAgentData && userAgentData.platform === 'iOS';
        const isChrome = userAgentData && userAgentData.brands.some(brand => brand.brand === 'Google Chrome');
        const isSafari = userAgentData && userAgentData.brands.some(brand => brand.brand === 'Apple Safari');
        const isFirefox = userAgentData && userAgentData.brands.some(brand => brand.brand === 'Mozilla Firefox');

        if(isiOS) {

            console.log('El dispositivo es iOS');

            if(window.navigator.standalone) {

                console.log('La aplicación está en modo standalone (iOS)');

                setIsStandalone(true);

            }

        }

        if (window.matchMedia('(display-mode: standalone)').matches) {

            console.log('La aplicación está en modo standalone');

            setIsStandalone(true);

            setInstalledPWA(true);

        } else if (window.matchMedia('(display-mode: browser)').matches) {
            
            console.log('La aplicación está en modo navegador');

            setShowinPWA(true);

        }

    }

    const openPWA = () => {

        //const pwaURL = `https://sivendoonline.com/${id}?source=pwa`; // Cambia esto a la URL de tu PWA
        //const pwaURL = `https://sivendoonline.com/${id}`; // Cambia esto a la URL de tu PWA
        const pwaURL = `${window.location.pathname}${window.location.search}`; // Cambia esto a la URL de tu PWA
        
        console.log('Abriendo la PWA en modo navegador...');

        navigate(pwaURL, { replace: true });

        /*if (!isStandalone) {
            // If the app is not in standalone mode, open the PWA URL in a new tab
            window.location.href = pwaURL;

        }*/

    };

    /*if (!supportsPWA) {
    
        return null;
    
    }*/

    const onClick = evt => {

        evt.preventDefault();
        
        if (!promptInstall) {

            return;
        
        }
        
        promptInstall.prompt();
    
    };

    return (
        <>
            {
                supportsPWA && (
                    <>
                        <BrowserView>
                            <div className='btn-sesion'>
                                <button className='icon' onClick={onClick} title="Instalar">
                                    <FontAwesomeIcon icon={faWpexplorer}/>
                                </button>
                            </div>
                        </BrowserView>
                        <MobileView>
                            <div className='btn-sesion'>
                                <button className='icon' onClick={onClick} title="Instalar">
                                {
                                        window?.navigator?.userAgentData.platform === 'Android' ? <FontAwesomeIcon icon={faAndroid} />  : <FontAwesomeIcon icon={faAppStoreIos} />
                                } 
                                </button>
                            </div>
                        </MobileView>
                    </>
                )
            }
            {!isStandalone && (
                <div className='btn-sesion'>
                    <button className='icon' onClick={openPWA} title="View in App">
                        <FontAwesomeIcon icon={faWpexplorer} /> Open App
                    </button>
                </div>
            )}
        </>
    );
};
