import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useNavigate, } from 'react-router';
import { useParams } from 'react-router';
import baseURL from '../url';
import './Stores.css';
import SwiperCore, { Navigation, Pagination, Autoplay } from 'swiper/core';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faAngleDoubleRight, faShoppingCart, faTrash, faStore, faMotorcycle, faClockRotateLeft, faTruck, faClipboardCheck, faInfoCircle, faPaperPlane, faListCheck, faSearch, faCodeCompare } from '@fortawesome/free-solid-svg-icons';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ProductosLoading from '../ProductosLoading/ProductosLoading';
import whatsappIcon from '../../images/wpp.png';
import BtnWhatsapp from '../../Components/BtnWhatsapp/BtnWhatsapp'
import BtnNotification from '../../Components/BtnNotification/BtnNotification'
import { useSearchParams } from 'react-router-dom';
import { FacebookShareButton, FacebookIcon} from 'react-share';
import { faFacebook, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import Swal from 'sweetalert2';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { Link as Anchor } from 'react-router-dom';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';

SwiperCore.use([Navigation, Pagination, Autoplay]);

export default function Products() {
    const { id } = useParams();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [categorias, setCategorias] = useState([]);
    const [loading, setLoading] = useState(true);
    const [fixedCategories, setFixedCategories] = useState(false);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalPedidoIsOpen, setModalPedidoIsOpen] = useState(false);
    const [modalActiveCodeIsOpen, setModalActiveCodeIsOpen] = useState(false);
    const [productoSeleccionado, setProductoSeleccionado] = useState(null);
    const [productoencarrito, setProductoencarrito] = useState(null);
    const [cantidad, setCantidad] = useState(1); // Estado para la cantidad de productos en el carrito
    const categoriasRefs = useRef([]);
    const categoriasInputRef = useRef(null);
    const swiperRef = useRef(null);
    const [productos, setProductos] = useState([]);
    const [addresses, setAddresses] = useState([]);
    const [todoslosproductos, setTodosLosProductos] = useState([]);
    const [categoriaSeleccionada, setCategoriaSeleccionada] = useState('Todo');
    const [existe, setExiste] = useState(false);
    const [imagenCart, setImagenCart] = useState('');
    const [pushNotData, setPushNotData] = useState('');
    const [notaEntrega, setNotaEntrega] = useState('');
    const [searchTerm, setSearchTerm] = useState("");
    const [searchTermLocation, setSearchTermLocation] = useState("");
    const [recaptchaToken, setRecaptchaToken] = useState("");
    const [store, setStore] = useState('');

    const { executeRecaptcha } = useGoogleReCaptcha();

    // Función para manejar el clic en una categoría
    const handleClickCategoria = (categoria) => {

        setCategoriaSeleccionada(categoria);

    };

    useEffect(() => {

        ////console.log(`This id is ${id}`);

        ////console.log(searchParams.get('id')); 

        const checkToken = () => {

            if(localStorage.getItem('pushNotData') !== null)

                setPushNotData(JSON.parse(localStorage.getItem('pushNotData')));
                
            if(localStorage.getItem('JWT_Token') === null) {

                fetch(`${baseURL}/checkToken.php?` + new URLSearchParams({

                    action: 'verify'
        
                }), {
        
                    method: 'POST'
        
                })
                .then(response => response.json())
        
                .then(data => {

                    //console.log(data);

                    localStorage.setItem('TMP_Token', data.token);
        
                })
                .catch(
        
                    error => console.error('Error al cargar productos:', error)
                    
                );

                /*toast.error('Por favor, inicie sesión para continuar');

                window.location.href = '/login';*/

            }

        }

        //checkToken();

        const getCaptchaToken = async() => {

            const token = await executeRecaptcha('login');

            setRecaptchaToken(token);
            
        }

        //getCaptchaToken();

        checkParams(searchParams);

        setTimeout(() => {

            cargarProductos();

        }, 500);
    
        window.addEventListener('scroll', handleScroll);
    
        return () => {
    
            window.removeEventListener('scroll', handleScroll);
    
        };
    
    }, [executeRecaptcha]);

    const checkParams = (params) => {

        //console.log(`Checking Params in URL`);

        //console.log(params); 

        if(params.has('id')) {

            const id = params.get('id');

            //console.log(`This id is ${id}`);

            cargarProducto(id, 'shorturl');

        } else if(params.has('pedido')) {

            const pedido = params.get('pedido');

            //console.log(`This pedido is ${pedido}`);

            cargarPedido(pedido, 'shorturl');

        } else if(params.has('code')) {

            const code = params.get('code');

            //console.log(`This code is ${code}`);

            setActivecode(code);

            navigate(`/activar/${code}`);

            return;

            //setOpenActiveCode();

        } else if(params.has('store')) {

            const store = params.get('store');

            //console.log(`This store is ${store}`);

            setStore(store);

            navigate(`/store/${store}`);

            return;

        }

    };

    const checaCodigo = (codigo) => {

        fetch(`${baseURL}/codigosGet.php?` + new URLSearchParams({

            codigo: codigo

        }), {

            method: 'GET',

            headers: new Headers({

                Authorization: 'Bearer ' + localStorage.getItem('JWT_Token'),

            }),

        })
            .then(response => response.json())
            .then(data => {

                if (data.codigos)

                    setExiste(true);

            })
            .catch(error => {

                console.error('Error:', error);

            });

    };

    const handleScroll = () => {

        if (window.scrollY > categoriasInputRef.current.offsetTop)

            setFixedCategories(true);
        
        else

            setFixedCategories(false);
        
    };

    const cargarProducto = useCallback(async (surl, stype=null) => {
        //const cargarProducto = (surl, stype=null) => {

        if (!executeRecaptcha) {

            //console.log("Execute recaptcha not yet available");

            return;

        }

        const token = await executeRecaptcha('login');

        fetch(`${baseURL}/productosGet.php?` + new URLSearchParams({

            action: stype,

            param: surl,

            recaptchaToken: token,

        }), {

            method: 'GET',

        })
        .then(response => response.json())

        .then(data => {

            if(stype === null) {

                const categoriasMap = new Map();

                data.productos.forEach(producto => {

                    const categoria = producto.categoria;

                    if (categoriasMap.has(categoria)) {

                        categoriasMap.get(categoria).push(producto);

                    } else {

                        categoriasMap.set(categoria, [producto]);

                    }

                });
                const categoriasArray = Array.from(categoriasMap, ([categoria, productos]) => ({ categoria, productos }));

                setCategorias(categoriasArray);

                setLoading(false);

                setProductos(data.productos); // Guardamos todos los productos

            } else if(stype==='shorturl') {

                let producto = data.productos.find(producto => {

                    return producto.shorturl === surl;
    
                });

                const prodTitulo = document.querySelector('title');

                prodTitulo.innerText = `${producto.titulo}`;

                document.querySelector("meta[property='og:image']").setAttribute("content", producto.imagen1);

                document.querySelector("meta[property='og:image:secure_url']").setAttribute("content", producto.imagen1);

                document.querySelector("meta[property='og:description']").setAttribute("content", producto.descripcion);

                document.querySelector("meta[property='og:quote']").setAttribute("content", producto.descripcion);

                //[rel='shortcut icon']").href = producto.imagen1;

                setLoading(false);
    
                openModal(producto);

            } else if(stype==='cart') {

                let producto = data.productos.find(producto => {

                    return producto.idProducto === surl;
    
                });

                //console.log(`Producto es`);

                //console.log(producto);
    
                setProductoencarrito(producto);

            }

        })
        .catch(

            error => console.error('Error al cargar productos:', error)
            
        );

    },[executeRecaptcha]);

    const cargarPedido = (surl, stype=null) => {

        let thisContacto = 0;

        if(localStorage.getItem('usuarios') !== null) {

            const dataUser = JSON.parse(localStorage.getItem('usuarios'));

            //console.log(dataUser);

            //console.log(`Id Usuario es ${dataUser[0].idUsuario}`);
    
            setContacto(dataUser[0].idUsuario);

            thisContacto = dataUser[0].idUsuario;

            //console.log(`Id Usuario guardado en variable contacto es ${contacto}`);
    
        }

        fetch(`${baseURL}/pedidosGet.php?` + new URLSearchParams({

            action: stype,

            pedido: surl,

            contacto: thisContacto

        }), {

            method: 'GET',

            headers: new Headers({

                Authorization: 'Bearer ' + localStorage.getItem('JWT_Token'),

            }),

        })
        .then(response => response.json())

        .then(data => {
            
            /*let producto = data.productos.find(producto => {

                return producto.shorturl === surl;

            });*/

            //console.log(data);

            setLoading(false);

            setOpenModalPedido(data.productos);

        })
        .catch(

            error => console.error('Error al cargar productos:', error)
            
        );

    };

    const setOpenModalPedido = (pedido) => {

        setListaPedidos(pedido);

        setModalPedidoIsOpen(true);

        setIsFocused(true);

    };

    const setCloseModalPedido = () => {

        setModalPedidoIsOpen(false);

        setIsFocused(false);

    };

    const setOpenActiveCode = () => {

        setModalActiveCodeIsOpen(true);

        setIsFocused(true);

    };

    const setCloseModalActiveCode = () => {

        setModalActiveCodeIsOpen(false);

        setIsFocused(false);

    };

    const cargarProductos = useCallback(async () => {
        //const cargarProductos = () => {
        if (!executeRecaptcha) {

            //console.log("Execute recaptcha not yet available");

            return;

        }

        const token = await executeRecaptcha('login');

        let ubicacion = '';

        //if(localStorage.getItem('ubicacion') !== null) ubicacion = localStorage.getItem('ubicacion');

        let byCity = '';

        //console.log(`Search Term Location es ${searchTermLocation}`);

        if(searchTermLocation!=='')

            byCity = searchTermLocation;

        fetch(`${baseURL}/storesGet.php?` + new URLSearchParams({

            recaptchaToken: token,

            ubicacion : ubicacion,

            byCity : byCity,

        }), {

            method: 'GET',

        })
            .then(response => response.json())

            .then(data => {

                const categoriasMap = new Map();

                data.productos.forEach(producto => {

                    const categoria = producto.categoria;

                    //console.log(`Categoria es ${categoria}`);

                    if (categoriasMap.has(categoria)) {

                        categoriasMap.get(categoria).push(producto);

                    } else {

                        categoriasMap.set(categoria, [producto]);

                    }

                });

                //console.log(`Categorias Map es`);

                //console.log(categoriasMap);

                const categoriasArray = Array.from(categoriasMap, ([categoria, productos]) => ({ categoria, productos }));

                setCategorias(categoriasArray);

                setLoading(false);

                setProductos(data.productos); // Guardamos todos los productos

                setTodosLosProductos(data.productos);

                //setSearchTermLocation(`${data.byCity.ciudad},${data.byCity.estado},${data.byCity.pais}`);

                setAddresses(data.cities);

                //console.log(`Ubicacion es ${data.byCity.ciudad},${data.byCity.estado},${data.byCity.pais}`);

                //console.log(`Ciudades son`);

                //console.log(addresses);

                //console.log(data.cities);

            })
            .catch(
                error => console.error('Error al cargar productos:', error)
            );

    },[executeRecaptcha, searchTermLocation]);

    const getRandomInt = (min, max) => {

        return Math.floor(Math.random() * (max - min + 1)) + min;

    }

    const newobtenerImagen = (item) => {

        const thisImagen = getRandomInt = (1,4);
        
        if (thisImagen === 1) {
        
            return item.imagen1;
        
        } else if (thisImagen === 2) {
        
            return item.imagen2;
        
        } else if (thisImagen === 3) {
        
            return item.imagen3;
        
        } else if (thisImagen === 4) {
        
            return item.imagen4;
        
        }

        //console.log(`No hay imagen para ${JSON.stringify(item)}`);
        
        return null;
    
    };

    const obtenerImagen = (item) => {

        //const thisImagen = getRandomInt = (1,4);
        
        if (item.imagen1) {
        
            return item.imagen1;
        
        } else if (item.imagen2) {
        
            return item.imagen2;
        
        } else if (item.imagen3) {
        
            return item.imagen3;
        
        } else if (item.imagen4) {
        
            return item.imagen4;
        
        }

        //console.log(`No hay imagen para ${JSON.stringify(item)}`);
        
        return null;
    
    };

    const openModal = (producto) => {

        if(producto.shortlink !== null) 

            navigate(`/store/${producto.shortlink}`);

        return;
        
        setProductoSeleccionado(producto);

        if(localStorage.getItem('productoSeleccionado')!==null)

            localStorage.removeItem('productoSeleccionado');

        localStorage.setItem('productoSeleccionado', JSON.stringify(producto));

        checkhorario(producto.abre,producto.cierra);

        setAdomicilio(producto.adomicilio);

        if(adomicilio === 1)

            setDeliveryOption('delivery');

        if(producto.pagos !== null) {

            let thisPago = JSON.parse(producto.pagos);

            setPagos(thisPago);

        }
        
        setModalIsOpen(true);
        
        /*
        const prodTitulo = document.querySelector('title');

        prodTitulo.innerText = `${producto.titulo}`;

        document.querySelector("meta[property='og:image']").setAttribute("content", producto.imagen1);

        document.querySelector("meta[property='og:image']").setAttribute("content", `${producto.imagen1}`);

        document.querySelector("meta[property='image']").setAttribute("content", `${producto.imagen1}`);

        */
    
    };

    const closeModal = () => {

        localStorage.removeItem('productoSeleccionado');
        
        setModalIsOpen(false);
        
        setCantidad(1);
    
    };

    const verificaVendedor = () => {

        const cart = JSON.parse(localStorage.getItem('cart')) || [];

        var vendedor = -1;

        if(cart.length > 0) 

            vendedor = cart.find(item => item.contacto !== productoSeleccionado.contacto);

        if(vendedor !== -1) {

            if (localStorage.getItem('usuarios') === null) {

                toast.error('Solo usuarios registrados pueden comprar a múltiples vendedores. Por favor, haga su registro o inicie sesión para continuar.');
    
                return false;
    
            }

        }

    };

    const addToCart = () => {

        //Aqui hay que verificar si el codigo es valido

        if (productoSeleccionado) {

            const cart = JSON.parse(localStorage.getItem('cart')) || [];

            var vendedor = -1;

            if(cart.length > 0) 

                vendedor = cart.findIndex(item => item.contacto !== productoSeleccionado.contacto);

            if(vendedor > -1) {

                if (localStorage.getItem('usuarios') === null) {

                    toast.error('Solo usuarios registrados pueden comprar a múltiples vendedores. Por favor, haga su registro y/o inicie sesión para continuar.');
        
                    return false;
        
                }

            }
            
            const existingItemIndex = cart.findIndex(item => item.idProducto === productoSeleccionado.idProducto);

            if (existingItemIndex !== -1) {
            
                // Si el producto ya existe en el carrito, actualizamos la cantidad
                cart[existingItemIndex].cantidad += cantidad;
            
            } else {
            
                // Si el producto no existe en el carrito, lo agregamos 
                cart.push({ idProducto: productoSeleccionado.idProducto, cantidad: cantidad, contacto: productoSeleccionado.contacto, pagos: productoSeleccionado.pagos, adomicilio: productoSeleccionado.adomicilio, negocio: productoSeleccionado.negocio, pagoseleccionado:'', domicilioseleccionado:'' });
            
            }

            // Actualizamos el carrito en el localStorage
            localStorage.setItem('cart', JSON.stringify(cart));

            // Llamamos a la función openModal() con la información del producto añadido
            openModal({ ...productoSeleccionado, cantidad });

            // Agregamos la llamada a cargarProductos para actualizar la lista de productos en Products
            cargarProductos();
            
            toast.success('Producto agregado');

        }

    };

    const apartaproducto = () => {

        if (productoSeleccionado) {

            const cart = JSON.parse(localStorage.getItem('cart')) || [];

            var vendedor = -1;

            if(cart.length > 0) 

                vendedor = cart.findIndex(item => item.contacto !== productoSeleccionado.contacto);

            if(vendedor > -1) {

                if (localStorage.getItem('usuarios') === null) {

                    toast.error('Solo usuarios registrados pueden comprar a múltiples vendedores. Por favor, haga su registro y/o inicie sesión para continuar.');
        
                    return false;
        
                }

            }
            
            const existingItemIndex = cart.findIndex(item => item.idProducto === productoSeleccionado.idProducto);

            if (existingItemIndex !== -1) {
            
                // Si el producto ya existe en el carrito, actualizamos la cantidad
                cart[existingItemIndex].cantidad += cantidad;
            
            } else {
            
                // Si el producto no existe en el carrito, lo agregamos 
                cart.push({ idProducto: productoSeleccionado.idProducto, cantidad: cantidad, contacto: productoSeleccionado.contacto, pagos: productoSeleccionado.pagos, adomicilio: productoSeleccionado.adomicilio, negocio: productoSeleccionado.negocio, pagoseleccionado:'', domicilioseleccionado:'' });
            
            }

            // Actualizamos el carrito en el localStorage
            localStorage.setItem('cart', JSON.stringify(cart));

            // Llamamos a la función openModal() con la información del producto añadido
            openModal({ ...productoSeleccionado, cantidad });

            // Agregamos la llamada a cargarProductos para actualizar la lista de productos en Products
            cargarProductos();
            
            toast.success('Producto agregado');

        }

    };

    const incrementCantidad = () => {

        setCantidad(cantidad + 1);

        if(cantidad > 1) {

            const thisBtnWhatsapp = document.getElementById('btnWhatsApp');

            thisBtnWhatsapp.style.display = 'none';

        }

    };

    const decrementCantidad = () => {

        if (cantidad > 1) {

            setCantidad(cantidad - 1);

        }

        if(cantidad === 1) {

            const thisBtnWhatsapp = document.getElementById('btnWhatsApp');

            thisBtnWhatsapp.style.display = 'block';

        }

    };

    const [cartItems, setCartItems] = useState([]);
    const [modalIsOpen1, setModalIsOpen1] = useState(false);
    const [modalIsOpen2, setModalIsOpen2] = useState(false);
    const [isFocused, setIsFocused] = useState(false);
    const [noteText, setNoteText] = useState('');
    const [thislocation, setLocation] = useState('');
    const [name, setName] = useState('');
    const [telefono, setTelefono] = useState('');
    const [activecode, setActivecode] = useState('');
    const [codigo, setCodigo] = useState('');
    const [contactos, setContactos] = useState([]);
    const [descuento, setDescuento] = useState(0);
    const [codigoValido, setCodigoValido] = useState(false);
    const [totalPrice, setTotalPrice] = useState(0);
    const [paymentMethod, setPaymentMethod] = useState('efectivo');
    const [deliveryOption, setDeliveryOption] = useState('pickup');
    const [contacto, setContacto] = useState(0);
    const [mensaje, setMensaje] = useState('');
    const [abierto, setAbierto] = useState(0);
    const [adomicilio, setAdomicilio] = useState(0);
    const [esusuario, setEsUsuario] = useState(false);
    const [pagos, setPagos] = useState(JSON.parse(`[
        {"Transferencia": "1"},
        {"Efectivo": "1"},
        {"Tarjeta": "0"},
        {"Vale de Despensa": "0"},
        {"Criptomoneda": "0"},
        {"Otro": "0"}
    ]`));
    const [codigos, setCodigos] = useState([]);
    const [listaPedidos, setListaPedidos] = useState(null);
    const buttonRef = useRef(null);

    useEffect(() => {
    
        getContacto();
    
        //cargarContacto();
    
    }, []);

    useEffect(() => {

        // Calcular el precio total al cargar el carrito o al actualizar los productos
        let totalPriceCalc = 0;
        
        cartItems.forEach(item => {
    
            totalPriceCalc += item.precio * item.cantidad;
    
        });
    
        setTotalPrice(totalPriceCalc);
    
    }, [cartItems]);

    const cargarContacto = () => {

        fetch(`${baseURL}/contactoGet.php`, {

            method: 'GET',

            headers: new Headers({

                Authorization: 'Bearer ' + localStorage.getItem('JWT_Token'),

            }),

        })
            .then(response => response.json())
            .then(data => {

                setContactos(data.contacto.reverse()[0] || []);

            })
            .catch(error => console.error('Error al cargar contactos:', error));
    };

    useEffect(() => {

        const fetchCartItems = async () => {

            const cart = JSON.parse(localStorage.getItem('cart')) || [];

            const promises = cart.map(async (cartItem) => {

                const producto = productos.find(producto => producto.idProducto === cartItem.idProducto);

                //console.log(`fetchCartItems Producto es`);

                //console.log(producto);

                if (producto) {

                    producto.domicilioseleccionado = cartItem?.domicilioseleccionado;

                    producto.pagoseleccionado = cartItem?.pagoseleccionado;
            
                    return {

                        ...producto,

                        cantidad: cartItem.cantidad

                    };

                } else {

                    console.error(`Producto con id ${cartItem.idProducto} no encontrado`);

                    return {

                        ...producto,

                        cantidad: cartItem.cantidad

                    };
                    
                }

                /*producto.domicilioseleccionado = cartItem?.domicilioseleccionado;

                producto.pagoseleccionado = cartItem?.pagoseleccionado;

                return {
                    ...producto,
                    cantidad: cartItem.cantidad,
                };*/

            });

            Promise.all(promises)
                .then((items) => {

                    setCartItems(items);

                    setLoading(false);

                })
                .catch((error) => {

                    console.error('Error al obtener detalles del carrito:', error);

                    setLoading(false);

                });
        };

        fetchCartItems();

    }, [productos, isFocused]);

    const verifycart = () => {

        const cart = JSON.parse(localStorage.getItem('cart')) || [];

        if (cart.length === 0) {

            toast.error('No hay productos en el carrito');

            return false;

        }

        return true;

        /*
        cart.map(item => {

            if (item.contacto !== contacto) {

                toast.error('No se pueden comprar productos de diferentes vendedores en una sola transacción. Por favor, elimine los productos de otros vendedores del carrito.');

                return false;

            }

        });
        */

    }

    const openModal1 = () => {

        setModalIsOpen1(true);

        setIsFocused(true);

    };

    const closeModal1 = () => {

        setModalIsOpen1(false);

        setIsFocused(false);

    };

    const openModal2 = () => {

        setModalIsOpen2(true);

    };

    const closeModal2 = () => {

        setModalIsOpen2(false);

    };

    const removeFromCart = (id) => {

        const updatedCart = cartItems.filter(item => item.idProducto !== id);

        setCartItems(updatedCart);

        localStorage.setItem('cart', JSON.stringify(updatedCart));

    };

    const clearCart = (confirm=true) => {

        if(confirm===true) {

            Swal.fire({
                title: '¿Estás seguro?',
                text: '¡No podrás revertir esto!',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Sí, vaciar',
                cancelButtonText: 'Cancelar',
            }).then((result) => {
                if (result.isConfirmed) {
    
                    setCartItems([]);
    
                    localStorage.removeItem('cart');
    
                    Swal.fire(
                        '¡Vacío!',
                        'El carrito se ha vaciado',
                        'success'
                    );
    
                    /*fetch(`${baseURL}/contactoDelete.php?idContacto=${idContacto}`, {
                        method: 'DELETE',
                        headers: new Headers({
    
                            Authorization: 'Bearer ' + localStorage.getItem('JWT_Token'),
            
                        }),
                    })
                    .then(response => response.json())
                    .then(data => {
                        Swal.fire(
                            '¡Eliminado!',
                            data.mensaje,
                            'success'
                        );
                        cargarContacto(contactoId,rol);
                    })
                    .catch(error => {
                        console.error('Error al eliminar contacto:', error);
                        toast.error(error);
                    });
                    */
                }
            });

        } else {

            setCartItems([]);

            localStorage.removeItem('cart');

            Swal.fire(
                'Pedido enviado!',
                'El pedido se ha enviado al vendedor',
                'success'
            );

        }

    };

    /*useEffect(() => {

        cargarCodigos();

    }, []);
    */

    const cargarCodigos = (contacto) => {

        fetch(`${baseURL}/codigosGet.php?` + new URLSearchParams({

            userId: contacto

        }), {

            method: 'GET',

            headers: new Headers({

                Authorization: 'Bearer ' + localStorage.getItem('JWT_Token'),

            }),

        })
            .then(response => response.json())
            .then(data => {

                setCodigos(data.codigos || []);

            })
            .catch(error => console.error('Error al cargar cupones:', error));

    };

    const handleWhatsappMessage = () => {

        const codigoDescuento = codigos.find(item => item.codigo === codigo);

        let descuentoActualizado = 0;

        if (codigoDescuento) {

            descuentoActualizado = codigoDescuento.descuento;

            setCodigoValido(true);

        } else {

            setCodigoValido(false);

        }

        let totalPrice = 0;

        cartItems.forEach(item => {

            totalPrice += item.precio * item.cantidad;

        });

        // Aplicar descuento y asegurarse de que el precio no sea negativo
        let totalPriceWithDiscount = totalPrice - descuentoActualizado;
        
        if (totalPriceWithDiscount < 0) {
        
            totalPriceWithDiscount = 0; // O cualquier otro manejo que desees
        
        }

        enviarPedido();

        const formattedTotalPrice = totalPriceWithDiscount?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");

        const phoneNumber = `${contactos.telefono}`;

        const cartDetails = cartItems.map((item) => (
            `\n*${item.titulo}* - Cantidad: ${item.cantidad}
            Precio: $${item.precio?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}\n`
        ));

        let noteMessage = '';

        if (thislocation.trim() !== '') {
        
            noteMessage += `\nUbicación: ${thislocation}`;
        
        }

        if (name.trim() !== '') {
        
            noteMessage += `\nNombre: ${name}`;
        
        }

        if (noteText.trim() !== '') {
        
            noteMessage += `\nNota: ${noteText}`;
        
        }
        
        if (codigo.trim() !== '') {
        
            noteMessage += `\nCodigo : ${codigo}
            \nDescuento de : $${descuentoActualizado?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
            `;
        
        }

        const paymentMessage = paymentMethod === 'efectivo' ? 'Pago en efectivo' : 'Pago por transferencia bancaria';
        
        const paymentMessage2 = deliveryOption === 'delivery' ? 'Envio a domicilio' : 'Retiro personalmente';


        const message = `¡Hola! 🌟 Estoy interesado en comprar:\n\n${cartDetails.join('')}\n${noteMessage}\n${paymentMessage2}\n${paymentMessage}\nTotal: $${formattedTotalPrice}`;

        const whatsappUrl = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(message)}`;

        //window.open(whatsappUrl, '_blank');
        setName('');

        setTelefono('');
        
        setLocation('');
        
        setNoteText('');
        
        setCodigo('');
        
        setDescuento(descuentoActualizado);
        
        setModalIsOpen1(false);
        
        setModalIsOpen2(false);
    
    };

    const enviarPedido = useCallback(async () => {
        //const enviarPedido = async () => {

        //e.preventDefault();

        //getContacto();

        //console.log(`Contacto es ${contacto}`);

        const form = document.getElementById("enviarPedido");

        const formData = new FormData(form);

        let iscaptcha = 2;

        let token;

        if(contacto === 'noregistrado') {

            const thisClient = name ?? 'noregistrado';
        
            formData.append('cliente', thisClient);
        
            formData.append('telefono', telefono);

            if (!executeRecaptcha) {

                console.error("Execute recaptcha not yet available");
    
                return;
    
            }
    
            token = await executeRecaptcha('login');

            iscaptcha = 1;

        } else {

            formData.append('cliente', contacto);

            if(localStorage.getItem('JWT_Token') !== null) {
            
                token = localStorage.getItem('JWT_Token');
    
            } /*else if(localStorage.getItem('TMP_Token') !== null){
    
                token = localStorage.getItem('TMP_Token');
    
                formData.append('guest', token);
    
            } */ else {
    
                toast.error('No se puede validar el pedido, haga reload para continuar');
    
                return;
    
            }

        }

        formData.append('productos', JSON.stringify(cartItems));
        
        const resetForm = () => {
        
            form.reset();

        };

        setMensaje('');

        if ( !formData.get('telefono') || !formData.get('productos') ) {

            toast.error('Por favor, complete todos los datos.');

            return;

        }

        setMensaje('Procesando...');

        formData.append('notifdst', pushNotData);

        let header;

        if(iscaptcha === 2) {

            header = new Headers({

                'Content-Type': 'application/json',

                'Authorization': 'Bearer ' + token,

            });

        } else {

            header = new Headers({

                'Accept': 'application/json',

            });

            formData.append('recaptchaToken', token);

        }

        try {

            const response = await fetch(`${baseURL}/pedidosPost.php`, {

                method: 'POST',

                body: formData,

                headers: header

            });

            const data = await response.json();

            if (data.mensaje) {

                setMensaje('');

                resetForm();

                toast.success(data.mensaje);

                clearCart(false);

            } else if (data.error) {
                
                setMensaje('');
                
                toast.error(data.error);
                
                //console.log(data.error);

            }

        } catch (error) {

            console.error('Error:', error);

            setMensaje('');

            toast.error('Error de conexión. Por favor, inténtelo de nuevo.');

        }

    },[executeRecaptcha, cartItems, contacto, pushNotData]);

    const getContacto = () => {

        setContacto('noregistrado');

        if(localStorage.getItem('usuarios') !== null) {

            const dataUser = JSON.parse(localStorage.getItem('usuarios'));

            //console.log(dataUser);

            //console.log(`Id Usuario es ${dataUser[0].idUsuario}`);
    
            setContacto(dataUser[0].idUsuario);

            //console.log(`Id Usuario guardado en variable contacto es ${contacto}`);
    
        }

    };

    const checkhorario = (abre,cierra) => {

        var d = new Date();

        var n = d.getHours();
        
        var m = d.getMinutes();

        var hora = n + ":" + m;

        if(abre === '00:00' && cierra === '00:00')

            setAbierto(2);

        else if(hora >= abre && hora <= cierra)

            setAbierto(1);

        else

            setAbierto(0);

    };

    const gotoStore = (id) => {

        if(id!==null || id!==undefined)

            navigate(`/store/${id}`);

        return;

    };

    const showInfo = (producto) => {

        /*
        https://blog.logrocket.com/using-react-toastify-style-toast-messages/

        toast.success("Success!", {
            icon: <FontAwesomeIcon icon={faCheckCircle} />,
        });

        toast.success("Success!", {
            autoClose: 2000, // milliseconds
        });

        */

        const alertTienda = (producto.tienda===1) ? 'success' : 'error';

        const StoreToast = ({ gotoStore, productoSeleccionado }) => (
            <div>
              <p>Cuenta con tienda virtual, visita su tienda</p>
              <button onClick={() => gotoStore(productoSeleccionado.shortlink)}>
                <FontAwesomeIcon icon={faStore} />
              </button>
            </div>
          );

        //toast(`${(producto.tienda===1) ? `Cuenta` : `No cuenta`} con tienda física`, { className: alertTienda });

        if(producto.tienda===1 && producto.shortlink!=='') {

            toast(<StoreToast gotoStore={gotoStore} productoSeleccionado={producto} />, {
                ToastComponent: StoreToast,
                className: alertTienda
              });

        } else {

            toast(`${(producto.tienda===1) ?
                `Cuenta` 
                : 'No cuenta'
                }  con tienda física`, { className: alertTienda });

        }

        const alertApartado = (producto.apartado===1) ? 'success' : 'error';

        toast(`${(producto.apartado===1) ? `Tiene ${producto.hapartado} hrs para realizar su compra cuando use el sistema de apartado` : `No cuenta con sistema de apartado`}`, { className: alertApartado});

        setTimeout(() => {

            const alertADomicilio = (producto.adomicilio===1) ? 'success' : 'error';

            toast(`${(producto.adomicilio===1) ? `Tiene` : `No tiene`} entrega a domicilio`, { className: alertADomicilio } );

            const alertAbierto = (abierto===1) ? 'success' : 'error';

            //toast(`Actualmente está ${(abierto===1) ? `abierto` : `cerrado`}`, { className: alertAbierto});

            toast(`${ (abierto === 3) ? `No tiene horario` : `Actualmente está ${ (abierto===1) ? `abierto` : `cerrado` }` }`, { className: alertAbierto})

        }, 3000);

        if(pagos!==null) {

            const pagosHabilitados = pagos.filter(pago => {

                const key = Object.keys(pago)[0];

                return pago[key] === "1";

            });

            //console.log(`Los pagos habilitados son:`);

            //console.log(pagosHabilitados);

            const pagosHabilitadosString = pagosHabilitados.map(pago => Object.keys(pago)[0]).join(', ');

            setTimeout(() => {

                toast(`Acepta los siguientes métodos de pago: ${pagosHabilitadosString}`);

            }, 2000);

        }

    };

    const fetchCartItemsAgain = async () => {

        const cart = JSON.parse(localStorage.getItem('cart')) || [];

        const promises = cart.map(async (cartItem) => {

            const producto = productos.find(producto => producto.idProducto === cartItem.idProducto);

            producto.domicilioseleccionado = cartItem?.domicilioseleccionado;

            producto.pagoseleccionado = cartItem?.pagoseleccionado;

            return {
                ...producto,
                cantidad: cartItem.cantidad,
            };

        });

        Promise.all(promises)
            .then((items) => {

                setCartItems(items);

                setLoading(false);

            })
            .catch((error) => {

                console.error('Error al obtener detalles del carrito:', error);

                setLoading(false);

            });
    };

    const setDomicilioseleccionado = (producto, domicilio) => {

        //console.log(`Producto es ${producto} y domcilio es ${domicilio}`);

        const cart = JSON.parse(localStorage.getItem('cart')) || [];

        const existingItemIndex = cart.findIndex(item => item.idProducto === producto);

        if (existingItemIndex !== -1) {

            cart[existingItemIndex].domicilioseleccionado = domicilio;

        }

        localStorage.setItem('cart', JSON.stringify(cart));

        fetchCartItemsAgain();

        //setCartItems(cart);

        /*const domicilios = document.getElementsByName(`deliveryOption-${producto}`);

        //console.log(`Domicilios son`);

        //console.log(domicilios);

        domicilios.forEach(domiciliosel => {

            if(domiciliosel.value === domicilio) {

                domiciliosel.checked = true;

            } else {

                domiciliosel.checked = false;

            }

        });*/

    };

    const setPagoseleccionado = (producto, pago) => {

        //console.log(`Producto es ${producto} y pago es ${pago}`);

        const cart = JSON.parse(localStorage.getItem('cart')) || [];

        const existingItemIndex = cart.findIndex(item => item.idProducto === producto);

        if (existingItemIndex !== -1) {

            cart[existingItemIndex].pagoseleccionado = pago;

        }

        localStorage.setItem('cart', JSON.stringify(cart));

        fetchCartItemsAgain();

        //setCartItems(cart);
        /*const payments = document.getElementsByName(`paymentMethod-${producto}`);

        payments.forEach(payment => {

            if(payment.value === pago) {

                payment.checked = true;

            } else {

                payment.checked = false;

            }

        });*/
        
        //document.getElementById(`paymentMethod-${producto}`).checked = true;

    };

    const checkSelected = (producto, dato, valor) => {

        const cart = JSON.parse(localStorage.getItem('cart')) || [];

        const existingItemIndex = cart.findIndex(item => item.idProducto === producto);

        if(dato === 'delivery') {

            if (existingItemIndex !== -1) {

                return (cart[existingItemIndex].domicilioseleccionado === 'delivery') ? 'true' : 'false';

            }

        } else if(dato === 'pickup') {

            if (existingItemIndex !== -1) {

                return (cart[existingItemIndex].domicilioseleccionado === 'pickup') ? 'true' : 'false';

            }

        } else if(dato === 'pago') {

            if (existingItemIndex !== -1) {

                return (cart[existingItemIndex].pagoseleccionado === valor) ? 'true' : 'false';

            }

        }

    }

    const contactarporWhatsapp = (item) => {

        const telefono = item.telefono;

        const link = `https://sivendoonline.com/?id=${item.shorturl}`;

        const message = `Hola ${item.cliente}, tu pedido id ${item.idPedido} listo para ser entregado. Este pedido consta de ${item.descripcion} con un importe de $${item.importe}, indicaste pagar con ${item.pago} y para la entrega indicaste ${item.entrega}. Gracias por tu preferencia. ${link} `;

        const whatsappUrl = `https://api.whatsapp.com/send?phone=${telefono}&text=${encodeURIComponent(message)}`;

        window.open(whatsappUrl, '_blank');

    };

    function obtenerCaracteresRepetidos(cadena, scar) {

        let caracteres = {};
        
        let repetidos = {};
    
        for (let char of cadena) {

            if(char === scar) {

                if (caracteres[char]) {

                    caracteres[char]++;
    
                } else {
    
                    caracteres[char] = 1;
    
                }

            }

        }
    
        // Filtrar los caracteres que se repiten
        let trepetidos = 0;

        for (let char in caracteres) {

            if(char === scar) {

                if (caracteres[char] > 1) {

                    repetidos[char] = caracteres[char];

                    trepetidos++;
    
                }

            }

        }

        console.log(`Repetidos son ${repetidos}`);

        return trepetidos;

    }   

    const handleSearchbyLocation = (event) => {

        const searchTermLocation = event.target.value;

        setSearchTermLocation(event.target.value);

        searchTermLocation.toLowerCase();

        console.log(`Buscando por ciudad/estado/pais ${searchTermLocation}`);

        let filterProductos = todoslosproductos;

        if(searchTermLocation !== "")

            filterProductos = todoslosproductos.filter(producto => producto.direccion.toLowerCase().includes(searchTermLocation));

        if(filterProductos.length === 0) 

            filterProductos = todoslosproductos.filter(producto => producto.descripcion.toLowerCase().includes(searchTermLocation));

        actualizaProductos(filterProductos);

    };

    const handleSearch = (event) => {

        const searchTerm = event.target.value;

        setSearchTerm(searchTerm);

        searchTerm.toLowerCase();

        let filterProductos = todoslosproductos;

        if(searchTerm !== "") 

            filterProductos = todoslosproductos.filter(producto => producto.categoria.toLowerCase().includes(searchTerm));

        if(filterProductos.length === 0) 

            filterProductos = todoslosproductos.filter(producto => producto.titulo.toLowerCase().includes(searchTerm));

        actualizaProductos(filterProductos);

    };

    const actualizaProductos = (productos) => {

        //console.log(`Actualizando lista de productos`);

        const categoriasMap = new Map();

        productos.forEach(producto => {

            const categoria = producto.categoria;

            if (categoriasMap.has(categoria)) {

                //console.log(`Agregando producto a categoria ${categoria} existente`);

                categoriasMap.get(categoria).push(producto);

            } else {

                //console.log(`Agregando producto a nueva categoria ${categoria}`);

                categoriasMap.set(categoria, [producto]);

            }

        });

        const categoriasArray = Array.from(categoriasMap, ([categoria, productos]) => ({ categoria, productos }));

        setCategorias(categoriasArray);

        setLoading(false);

        setProductos(productos);

    };

    const validaUsuario = useCallback(async (e) => {

        e.preventDefault();

        if (!executeRecaptcha) {

            console.err("Execute recaptcha not yet available");

            return;

        }

        const token = await executeRecaptcha('login');

        fetch(`${baseURL}/verificaUsuario.php?` + new URLSearchParams({

            recaptchaToken: token,

            codigo: activecode

        }), {

            method: 'GET',

            /*headers: new Headers({

                Authorization: 'Bearer ' + localStorage.getItem('JWT_Token'),

            }),*/

        })
            .then(response => response.json())
            .then(data => {

                if (data.success===true) {

                    toast.success(data.mensaje);

                    setTimeout(() => {

                        setCloseModalActiveCode(false);

                        navigate('/login');

                    }, 2000);

                } else 

                    toast.error(data.error);

            })
            .catch(error => {

                console.error('Error:', error);

            });
        
    }, [executeRecaptcha,,activecode, navigate]);

    /*const getData = (idProducto) => {

        //console.log(`Item para buscar es`);

        //console.log(idProducto);

        if(idProducto === null || idProducto === undefined) 

            return;

        cargarProducto(idProducto, 'cart');

        setTimeout(() => {

            const thisPagos = JSON.parse(productoencarrito.pagos) ?? [];

            const thisEntrega = productoencarrito.adomicilio ?? 0;

            const pago = (thisEntrega === 1) 
            ? 
                <>
                    <label>Opciones de entrega</label>
                    <div>
                        <input
                            type="radio"
                            id="delivery"
                            name="deliveryOption"
                            value="delivery"
                            checked={deliveryOption === 'delivery'}
                            onChange={() => setDeliveryOption('delivery')}
                            disabled={(adomicilio === 1) ? 'false' : 'true' }
                        />
                        <label htmlFor="delivery">Envío a domicilio</label>
                    </div>
                    <div>
                        <input
                            type="radio"
                            id="pickup"
                            name="deliveryOption"
                            value="pickup"
                            checked={deliveryOption === 'pickup'}
                            onChange={() => setDeliveryOption('pickup')}
                        />
                        <label htmlFor="pickup">Retirar personalmente</label>
                    </div>
                </>
            :
                <div>
                    <input
                        type="radio"
                        id="pickup"
                        name="deliveryOption"
                        value="pickup"
                        checked={deliveryOption === 'pickup'}
                        onChange={() => setDeliveryOption('pickup')}
                    />
                    <label htmlFor="pickup">Retirar personalmente</label>
                </div>;

            const metodos = thisPagos.map((pago, index) => {
                                                                
                const key = Object.keys(pago)[0];

                return (

                    (pago[key] === "1")
                    ?
                    <div key={index}>
                        <input
                            type="radio"
                            id={key}
                            name="paymentMethod"
                            value={key}
                            onChange={() => setPaymentMethod(key)}
                        />
                        <label htmlFor={key}>{key}</label>
                    </div>
                    :
                    null
                );
            
            });

            return (

                <>
                    { pago }

                    { metodos }

                </>
            )

        },2000);

    };*/

    const handleOnSearch = (string, results) => {
        //console.log(string, results);
    };

    const handleOnHover = (result) => {
        //console.log(result);
    };

    const handleOnSelect = (item) => {
        //console.log(item);
    };

    const handleOnFocus = () => {
        //console.log('Focused');
    };

    const formatResult = (item) => {
        return (
            <>
                <span style={{ display: 'block', textAlign: 'left' }}>id: {item.id}</span>
                <span style={{ display: 'block', textAlign: 'left' }}>name: {item.name}</span>
            </>
        );
    };

    return (
        <div className='ProductsContain'>
            { 
            /*<HelmetMetaData 
                title={`${( productoSeleccionado?.titulo ) ? productoSeleccionado.titulo : 'SIVendo Online'}`}
                description={`${ (productoSeleccionado?.descripcion) ? productoSeleccionado?.descripcion : 'Tu Red Local de Negocios'}`}
                url={`${( (productoSeleccionado?.shorturl) ? `https://sivendoonline.com/?id=${productoSeleccionado?.shorturl}` : `https://sivendoonline.com` )}`}
                quote={"Mira este producto en Sivendo Online"}
                image={`${ (productoSeleccionado?.imagen1) ? productoSeleccionado?.imagen1 : `https://sivendoonline.com/logo_sivendo_192x192.webp`}`}
            ></HelmetMetaData> */ }

            { !activecode && (
                <>
                    <ToastContainer />
                    <div className="categoriasInputs">
                        <div className='categoriasInputs'>
                            <FontAwesomeIcon icon={faSearch} className="search-icon" />
                            <input
                                type="text"
                                placeholder="Categoría o Tienda"
                                value={searchTerm}
                                onChange={handleSearch}
                                className="input"
                            />
                        </div>
                        <div className='categoriasInputs'>
                            <input
                                type="text"
                                placeholder="Ciudad o Estado o País"
                                value={searchTermLocation}
                                /*onFocus={ () => setSearchTermLocation('') }*/
                                /*onChange={e => setSearchTermLocation(e.target.value)} */
                                onChange={handleSearchbyLocation} 
                                className="input"
                                /*list="datalistAddresses"*/
                            />
                            {/*<datalist 
                            
                                id="datalistAddresses"
                                onClick={handleSearchbyLocation}
                            >
                                {
                                    addresses.map((item) =>
                                        <option key={item} value={item} />
                                    )
                                }
                            </datalist>
                             <button type="button" ref={buttonRef} className="btnSearch" onClick={handleSearchbyLocation}>
                                <FontAwesomeIcon icon={faSearch} className="search-icon" />
                            </button> */}
                        </div>
                    </div>
                    <div className={`categoriasInputs ${fixedCategories ? 'fixed' : ''}`} ref={categoriasInputRef}>

                        {categorias.length > 0 &&
                            <input
                                type="button"
                                value="Todo"
                                onClick={() => handleClickCategoria('Todo')}
                                style={{
                                    backgroundColor: categoriaSeleccionada === 'Todo' ? '#0074E4' : '',
                                    color: categoriaSeleccionada === 'Todo' ? '#fff' : '',
                                    borderBottom: categoriaSeleccionada === 'Todo' ? '2px solid #0074E4' : 'none'
                                }}
                            />
                        }

                        {categorias.map(({ categoria }, index) => (
                            <input
                                key={categoria}
                                type="button"
                                value={categoria}
                                onClick={() => handleClickCategoria(categoria)}
                                style={{
                                    backgroundColor: categoriaSeleccionada === categoria ? '#0074E4' : '',
                                    color: categoriaSeleccionada === categoria ? '#fff' : '',
                                    borderBottom: categoriaSeleccionada === categoria ? '2px solid #0074E4' : 'none'
                                }}
                            />
                        ))}
                    </div>
                    <div>
                        {loading ? (
                            <ProductosLoading />
                        ) : (
                            <div >
                                {categoriaSeleccionada === 'Todo' && (
                                    <div className='Products'>
                                        {productos.some(item => item.masVendido === "si") && (
                                            <div className='categoriSection'>
                                                <Swiper
                                                    effect={'coverflow'}
                                                    grabCursor={true}
                                                    slidesPerView={'auto'}
                                                    id='swiper_container_products'
                                                >
                                                    {productos.filter(item => item.masVendido === "si").map(item => (
                                                        <SwiperSlide id='SwiperSlide-scroll-products-masvendidos' key={item.idProducto}>
                                                            <div className='cardProdcutmasVendido' onClick={() => openModal(item)}>
                                                                <img src={item.logo} alt="imagen" />
                                                                <h6 className='masVendido'>Novedades</h6>
                                                                <div className='cardText'>
                                                                    <h4>{item.titulo}</h4>
                                                                    <p>{item.descripcion}</p>
                                                                    <h4>{item.currency} {`${item?.precio}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".")}</h4>
                                                                    <FontAwesomeIcon icon={faAngleDoubleRight} className='iconCard2' />
                                                                </div>

                                                            </div>
                                                        </SwiperSlide>
                                                    ))}
                                                </Swiper>
                                            </div>

                                        )}

                                        {categorias.map(({ categoria, productos }, index) => (
                                            <div key={categoria} className='categoriSection' ref={ref => categoriasRefs.current[index] = ref}>

                                                <h3 className='title'>{categoria}</h3>
                                                <Swiper
                                                    effect={'coverflow'}
                                                    grabCursor={true}
                                                    slidesPerView={'auto'}
                                                    id='swiper_container_products'
                                                >
                                                    {productos.map(item => (
                                                        <SwiperSlide id='SwiperSlide-scroll-products' key={item.idProducto}>
                                                            <div className='cardProdcut' key={item.idProducto} onClick={() => openModal(item)}>
                                                                <img src={item.logo} alt="imagen" />
                                                                <div className='cardText'>
                                                                    <h4>{item.titulo}</h4>
                                                                    <p>{item.descripcion}</p>
                                                                    <h3>{item.currency} {`${item?.precio}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".")}</h3>
                                                                    <FontAwesomeIcon icon={faAngleDoubleRight} className='iconCard' />
                                                                </div>
                                                            </div>
                                                        </SwiperSlide>
                                                    ))}
                                                </Swiper>
                                            </div>
                                        ))}
                                    </div>
                                )}

                                <div className='categoriSectionSelected'>
                                    {productos
                                        // Filtra los productos solo para la categoría seleccionada
                                        .filter(item => categoriaSeleccionada !== 'Todo' && item.categoria === categoriaSeleccionada)
                                        // Mapea para renderizar los productos dentro de la categoría
                                        .map(item => (
                                            <div key={item.idProducto}>
                                                <div className='cardProdcutSelected' onClick={() => openModal(item)}>
                                                    <img src={obtenerImagen(item)} alt="imagen" />
                                                    <div className='cardTextSelected'>
                                                        <h4>{item.titulo}</h4>
                                                        <p>{item.descripcion}</p>
                                                        <h4>{item.currency} {`${item?.precio}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".")}</h4>
                                                        <FontAwesomeIcon icon={faAngleDoubleRight} className='iconCard' />
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                </div>
                            </div>
                        )}

                    </div>
                    <button onClick={openModal1} className='cartIconFixed'>
                        {
                            cartItems.length > 0 ? (
                                <span>{cartItems.length}</span>
                            ) : (
                                <>
                                </>
                            )
                        }
                        <FontAwesomeIcon icon={faShoppingCart} /> 
                    </button>
                </>
            )}

            {/*Producto Seleccionado*/}
            <Modal isOpen={modalIsOpen}
                onRequestClose={closeModal}
                className="modal-detail"
                overlayClassName="overlay-detail">
                {productoSeleccionado && (
                    <>
                        <div className='modal-content-detail'>
                            <button onClick={closeModal} className='back'> 
                                <FontAwesomeIcon icon={faArrowLeft} />
                            </button>
                            <Swiper
                                effect={'coverflow'}
                                grabCursor={true}
                                loop={true}
                                slidesPerView={'auto'}
                                coverflowEffect={{ rotate: 0, stretch: 0, depth: 100, modifier: 2.5 }}
                                navigation={{ nextEl: '.swiper-button-next', prevEl: '.swiper-button-prev' }}
                                autoplay={{ delay: 3000 }}
                                pagination={{ clickable: true }}
                                onSwiper={(swiper) => {
                                    //console.log(swiper);
                                    swiperRef.current = swiper;
                                }}
                                id='swiper_container_Imgs'
                            >
                                {productoSeleccionado.imagen1 ? (
                                    <SwiperSlide id='SwiperSlide-scroll-img'>
                                        <img src={productoSeleccionado.imagen1} alt="" />
                                    </SwiperSlide>
                                ) : (
                                    null
                                )}
                                {productoSeleccionado.imagen2 ? (
                                    <SwiperSlide id='SwiperSlide-scroll-img'>
                                        <img src={productoSeleccionado.imagen2} alt="" />
                                    </SwiperSlide>
                                ) : (
                                    null
                                )}
                                {productoSeleccionado.imagen3 ? (
                                    <SwiperSlide id='SwiperSlide-scroll-img'>
                                        <img src={productoSeleccionado.imagen3} alt="" />
                                    </SwiperSlide>
                                ) : (
                                    null
                                )}
                                {productoSeleccionado.imagen4 ? (
                                    <SwiperSlide id='SwiperSlide-scroll-img'>
                                        <img src={productoSeleccionado.imagen4} alt="" />
                                    </SwiperSlide>
                                ) : (
                                    null
                                )}
                            </Swiper>
                            <div className='modalText'>
                                <div className='deFlexShare'>
                                    <h2>
                                        {productoSeleccionado.titulo} ({productoSeleccionado.categoria})
                                    </h2>
                                    <FontAwesomeIcon className="iconShareFacebook" onClick={() => { showInfo(productoSeleccionado)} } icon={faInfoCircle} style={{ cursor: 'pointer'}}/>
                                    <FontAwesomeIcon style={{ cursor: 'pointer' }} onClick={() => { if(productoSeleccionado.shortlink !== null) gotoStore(productoSeleccionado.shortlink)} } title={`${(productoSeleccionado.tienda===1) ?
                                        ( (productoSeleccionado.shortlink!=='') ? `Cuenta con tienda virtual, visita su tienda aquí` : `Cuenta` ) : 'No cuenta'} con tienda física`} icon={faStore} className={ (productoSeleccionado.tienda===1) ? 'iconShareEnabled' : 'iconShareDisabled' } />
                                    <FontAwesomeIcon title={`${(productoSeleccionado.apartado===1) ? `Tiene ${productoSeleccionado.hapartado} hrs para realizar su compra cuando use el sistema de apartado` : `No cuenta con sistema de apartado`}`} icon={faClipboardCheck} className={ (productoSeleccionado.apartado===1) ? 'iconShareEnabled' : 'iconShareDisabled' }  onclick={ ( productoSeleccionado.apartado===1 && esusuario === 1) ? '' : '' } />
                                    <FontAwesomeIcon title={`${(productoSeleccionado.adomicilio===1) ? 'Tiene' : 'No tiene'} entrega a domicilio`} icon={faTruck} className={ (productoSeleccionado.adomicilio===1) ? 'iconShareEnabled' : 'iconShareDisabled' }  />
                                    <FontAwesomeIcon title={`${ (abierto === 3) ? `No tiene horario` : `Actualmente está ${ (abierto===1) ? `abierto` : `cerrado` }` }` }  icon={faClockRotateLeft} className={ (abierto===1) ? 'iconShareEnabled' : 'iconShareDisabled' } />
                                    <FacebookShareButton url={`https://sivendoonline.com/?id=${productoSeleccionado.shorturl}`} quote={productoSeleccionado.descripcion} hashtag="#SivendoOnline">
                                        <FontAwesomeIcon icon={faFacebook} className='iconShareFacebook' title="Compartir en Facebook" />
                                    </FacebookShareButton>
                                </div>
                                {/*<h2>
                                    {productoSeleccionado.titulo} ({productoSeleccionado.categoria})
                                    { <div className='deFlexShare'>
                                    { 
                                        <FacebookShareButton
                                            url={`https://${window.location.hostname}/?id=${productoSeleccionado.shorturl}`}
                                            quote={"Mira este producto en Sivendo Online"}
                                            hashtag="#SivendoOnline"
                                        >
                                            <FacebookIcon size={40} round /> 
                                        </FacebookShareButton> 
                                    }
                                    </div>
                                    
                                </h2> */ }
                                <p>{productoSeleccionado.descripcion} </p>
                                <h2>
                                    {productoSeleccionado.currency} {`${productoSeleccionado?.precio}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                                </h2>
                                {
                                    (localStorage.getItem('contacto')!==null)
                                    ? <BtnWhatsapp />
                                    : <BtnNotification />
                                }
                            </div>
                            <div className='deFlexGoTocart'>
                                <div className='deFlexCart'>
                                    <button onClick={decrementCantidad}>-</button>
                                    <span>{cantidad}</span>
                                    <button onClick={incrementCantidad}>+</button>
                                </div>
                                <button onClick={addToCart} className='btn'>Agregar  (  {`${productoSeleccionado.currency} ${productoSeleccionado?.precio * cantidad}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".")} )</button>
                                { /*<button onClick={() => { showInfo(productoSeleccionado)} } className='btn'>
                                    <FontAwesomeIcon icon={faInfoCircle} />
                                </button> */ }
                            </div>
                        </div>
                    </>
                )}
            </Modal>
            {/*Producto Seleccionado*/}
            {/*carrito*/}
            <Modal
                isOpen={modalIsOpen1}
                className="modal-cart"
                overlayClassName="overlay-cart"
                onRequestClose={closeModal1}
                >
                <div className='deFLex'>
                    <button onClick={closeModal1} >
                        <FontAwesomeIcon icon={faArrowLeft} />
                    </button>
                    { cartItems?.length === 0 ? (
                            <p className='nohayTitle'> No has agregado productos</p>
                        ) : (
                            <button onClick={() => clearCart()} className='deleteToCart'>Vaciar carrito</button>
                        )
                    }
                </div>
                {cartItems?.length === 0 ?
                    (<p className='nohay'> No has agregado productos</p>)
                    : (<>
                        <div className="modal-content-cart">
                            { loading ? (
                                <p>Cargando...</p>
                            ) : (
                                <div>

                                    {cartItems.map((item) => (
                                        <div key={item.idProducto} className='cardProductCart'>
                                            <img src={obtenerImagen(item)} alt="imagen" />
                                            <div className='cardProductCartText'>
                                                <h3>{item.titulo} </h3>
                                                <p>Vendedor: {item.negocio}</p>
                                                <p>Cantidad: {item.cantidad} x {item.currency} {item?.precio?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}</p>
                                                <p>
                                                    <label>Opciones de entrega</label>
                                                    {
                                                        (item.adomicilio === 1) 
                                                        ? 
                                                            <>
                                                                <div 
                                                                    style={
                                                                        {
                                                                            textIndent:'1em', 
                                                                            textAlign:'left' 
                                                                        } 
                                                                    }
                                                                >
                                                                    <input
                                                                        type="radio"
                                                                        id={`deliveryOption-${item.idProducto}`}
                                                                        name={`deliveryOption-${item.idProducto}`}
                                                                        value="delivery"
                                                                        checked={item.domicilioseleccionado === 'delivery'}
                                                                        onChange={() => setDomicilioseleccionado(item.idProducto, 'delivery')}
                                                                    />
                                                                    <label htmlFor={`deliveryOption-${item.idProducto}`}>Envío a domicilio</label>
                                                                </div>
                                                                <div
                                                                    style={
                                                                        {
                                                                            textIndent:'1em', 
                                                                            textAlign:'left' 
                                                                        } 
                                                                    }
                                                                >
                                                                    <input
                                                                        type="radio"
                                                                        id={`pickupOption-${item.idProducto}`}
                                                                        name={`deliveryOption-${item.idProducto}`}
                                                                        value="pickup" 
                                                                        checked={item.domicilioseleccionado === 'pickup'}
                                                                        onChange={() => setDomicilioseleccionado(item.idProducto, 'pickup')}
                                                                    />
                                                                    <label htmlFor={`pickupOption-${item.idProducto}`}>Retirar personalmente</label>
                                                                </div>
                                                            </>
                                                        :
                                                            <div
                                                                style={
                                                                    {
                                                                        textIndent:'1em', 
                                                                        textAlign:'left' 
                                                                    } 
                                                                }
                                                            >
                                                                <input
                                                                    id={`deliveryOption-${item.idProducto}`}
                                                                    name={`deliveryOption-${item.idProducto}`}
                                                                    type="radio"
                                                                    value="pickup"
                                                                    checked={item.domicilioseleccionado === 'pickup'}
                                                                    onChange={() => setDomicilioseleccionado(item.idProducto, 'pickup')}
                                                                />
                                                                <label htmlFor={`deliveryOption-${item.idProducto}`}>Retirar personalmente</label>
                                                            </div>
                                                    }
                                                </p>
                                                <p>
                                                    <label>Pago con</label>
                                                    {
                                                        (item.pagos) ?

                                                            JSON.parse(item.pagos).map((pago, index) => {
                                                                    
                                                                const key = Object.keys(pago)[0];
                                                
                                                                return (
                                                
                                                                    (pago[key] === "1")
                                                                    ?
                                                                        <div key={index}
                                                                            style={
                                                                                {
                                                                                    textIndent:'1em', 
                                                                                    textAlign:'left' 
                                                                                } 
                                                                            }
                                                                        >
                                                                            <input
                                                                                type="radio"
                                                                                id={`paymentMethod-${key}-${item.idProducto}`}
                                                                                name={`paymentMethod-${item.idProducto}`}
                                                                                value={key}
                                                                                checked={item.pagoseleccionado === key}
                                                                                onChange={() => setPagoseleccionado(item.idProducto, key)}
                                                                            />
                                                                            <label htmlFor={`paymentMethod-${key}-${item.idProducto}`}>{key}</label>
                                                                        </div>
                                                                    :
                                                                    null
                                                                );
                                                            
                                                            })

                                                        :
                                                            <>
                                                                <p>El vendedor le contactará para definirlo.</p>
                                                            </>
                                                    }
                                                </p>
                                            </div>
                                            <button onClick={() => removeFromCart(item.idProducto)} className='deleteCart'>
                                                <FontAwesomeIcon icon={faTrash} />
                                            </button>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                        <div className='deColumnCart'>
                            <h4 style={{ textAlign:'right'}}>Total: ${totalPrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}</h4>
                            <form id="enviarPedido">
                                <div className='deFLexBtns'>
                                        {
                                            (contacto==='noregistrado') 
                                            ?
                                            <>
                                                <div className="modal-send-form">
                                                    { /* <input
                                                        type="text"
                                                        id="name"
                                                        name="name"
                                                        value={name}
                                                        onChange={(e) => setName(e.target.value)}
                                                        placeholder='Nombre (opcional)'
                                                    /> */ }
                                                    <input
                                                        type="tel"
                                                        id="telefono"
                                                        name="telefono"
                                                        value={telefono}
                                                        onChange={(e) => setTelefono(e.target.value)}
                                                        placeholder='Téléfono de Contacto'
                                                        required={true}
                                                    />
                                                </div>
                                            </>
                                            :
                                            null
                                        }
                                        <button type="button" onClick={() => handleWhatsappMessage()} className='btn'>
                                            Enviar <FontAwesomeIcon icon={faPaperPlane} className='icon' />
                                        </button>

                                    {/*<button className='btnWnp' onClick={openModal2}>
                                        Generar Pedido <FontAwesomeIcon icon={faListCheck} className='icon' />
                                    </button> */}
                                    { /* <button className='btnWpp' onClick={openModal2}>
                                        Pedir por  WhatsApp<img src={whatsappIcon} alt="WhatsApp" />
                                    </button> */}

                                </div>
                            </form>
                        </div>

                        <Modal
                            isOpen={modalIsOpen2}
                            onRequestClose={closeModal2}
                            className="modal-cart"
                            overlayClassName="overlay-cart"
                        >
                            <div className='deFLex'>
                                <button onClick={closeModal2} >
                                    <FontAwesomeIcon icon={faArrowLeft} />
                                </button>
                                <h4>Agregar Detalles</h4>
                            </div>
                            <form id="OldenviarPedido">
                                <div className="modal-send-form">
                                    <input
                                        type="text"
                                        id="name"
                                        name="name"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                        placeholder='Nombre (opcional)'
                                    />
                                    <div className='deFLexRadio'>
                                        <label>Opciones de entrega</label>
                                        <div>
                                            <input
                                                type="radio"
                                                id="delivery"
                                                name="deliveryOption"
                                                value="delivery"
                                                checked={deliveryOption === 'delivery'}
                                                onChange={() => setDeliveryOption('delivery')}
                                                disabled={(adomicilio === 1) ? 'false' : 'true' }
                                            />
                                            <label htmlFor="delivery">Envío a domicilio</label>
                                        </div>
                                        <div>
                                            <input
                                                type="radio"
                                                id="pickup"
                                                name="deliveryOption"
                                                value="pickup"
                                                checked={deliveryOption === 'pickup'}
                                                onChange={() => setDeliveryOption('pickup')}
                                            />
                                            <label htmlFor="pickup">Retirar personalmente</label>
                                        </div>
                                    </div>
                                    <div className='deFLexRadio'>
                                        <label>Formas de pago</label>
                                        <div>
                                            { (pagos) 
                                                ?
                                                    pagos.map((pago, index) => {
                                                            
                                                        const key = Object.keys(pago)[0];

                                                        return (

                                                            (pago[key] === "1")
                                                            ?
                                                            <div key={index}>
                                                                <input
                                                                    type="radio"
                                                                    id={key}
                                                                    name="paymentMethod"
                                                                    value={key}
                                                                    onChange={() => setPaymentMethod(key)}
                                                                    /*onChange={() => {
                                                                        const updatedPagos = pagos.map(pago => {
                                                                            if (Object.keys(pago)[0] === key) {
                                                                                return { [key]: pago[key] === "1" ? "0" : "1" };
                                                                            }
                                                                            return pago;
                                                                        });
                                                                        setPagos(updatedPagos);
                                                                    }}*/
                                                                />
                                                                <label htmlFor={key}>{key}</label>
                                                            </div>
                                                            :
                                                            null
                                                        );
                                                    
                                                    })
                                                :
                                                <>
                                                    <div >
                                                        <input
                                                            type="radio"
                                                            id="efectivo"
                                                            name="paymentMethod"
                                                            value="efectivo"
                                                            checked={paymentMethod === 'efectivo'}
                                                            onChange={() => setPaymentMethod('efectivo')}
                                                        />
                                                        <label htmlFor="efectivo">Efectivo</label>
                                                    </div>
                                                    <div >
                                                        <input
                                                            type="radio"
                                                            id="transferencia"
                                                            name="paymentMethod"
                                                            value="transferencia"
                                                            checked={paymentMethod === 'transferencia'}
                                                            onChange={() => setPaymentMethod('transferencia')}
                                                        />
                                                        <label htmlFor="transferencia">Transferencia</label>
                                                    </div>
                                                </>
                                            }
                                        </div>
                                    </div>
                                    <input
                                        type="text"
                                        id="codigo"
                                        name="codigo"
                                        value={codigo}
                                        onChange={(e) => () => {

                                             setCodigo(e.target.value);

                                             checaCodigo(codigo);

                                            }
                                        }
                                        placeholder='Codigo de descuento (opcional)'
                                    />
                                    <textarea
                                        id="notaspedido"
                                        name="notaspedido"
                                        placeholder="Agrega una nota (opcional)"
                                        value={noteText}
                                        onChange={(e) => setNoteText(e.target.value)}
                                    />
                                    <button type="button" onClick={handleWhatsappMessage} className='btn'>
                                        Enviar <FontAwesomeIcon icon={faPaperPlane} className='icon' />
                                    </button>
                                </div>
                            </form>
                        </Modal>

                    </>)}

            </Modal>
            {/*carrito*/}
            { /* Modal Pedido */}
            <Modal isOpen={modalPedidoIsOpen}
                onRequestClose={setCloseModalPedido}
                className="modal-detail-table"
                overlayClassName="overlay-detail"
            >
            <div className='deFLex'>
                <button onClick={setCloseModalPedido} >
                    <FontAwesomeIcon icon={faArrowLeft} />
                </button>
                <h4>Detalles del Pedido</h4>
            </div>
                {listaPedidos && (
                    <>
                        <div className='modal-content-detail'>
                            <div className='table-container'>
                                <table className='table'>
                                    <thead>
                                        <tr>
                                            <th>Imagen</th>
                                            <th>Fecha</th>
                                            <th>Cliente</th>
                                            <th>Descripcion</th>
                                            <th>Cantidad</th>
                                            <th>Precio</th>
                                            <th>Descuento</th>
                                            <th>Importe</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {listaPedidos.map(item => (
                                            <tr key={item.idProducto}>
                                                <td>
                                                    {item.imagen ? (
                                                        <img src={item.imagen} alt="imagen1" />
                                                    ) : (
                                                        <span className='imgNonetd'>
                                                            Sin imagen
                                                        </span>
                                                    )}
                                                </td>
                                                <td style={{ textAlign: 'center' }}>{item.fecha}</td>
                                                <td>{item.cliente}</td>
                                                <td>{item.descripcion}</td>
                                                <td style={{ textAlign: 'right' }}>{item.cantidad}</td>
                                                <td style={{textAlign: 'right', color: '#008000'}}>
                                                    ${`${item?.precio}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                                                </td>
                                                <td style={{ textAlign: 'right' }}>
                                                    ${`${item?.descuento}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                                                </td>
                                                <td style={{ textAlign: 'right' }}>
                                                    ${`${item?.importe}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            <div className='deFlex'>
                                <form id="frmcontacto">
                                    <div className="modal-send-form">
                                        <textarea
                                            id="notasentrega"
                                            name="notasentrega"
                                            placeholder="Agrega una nota"
                                            value={notaEntrega}
                                            onChange={(e) => setNotaEntrega(e.target.value)}
                                        />
                                        <button type="button" onClick={() => {contactarporWhatsapp(listaPedidos)}} className='btn'>
                                            Contactar <FontAwesomeIcon icon={faWhatsapp} className='icon' />
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </>
                )}
            </Modal>
            { /* Modal Pedido */}

            { /* Modal Activar Cuenta */}
            <Modal isOpen={modalActiveCodeIsOpen}
                onRequestClose={setCloseModalActiveCode}
                className="modal-detail-table"
                overlayClassName="overlay-detail"
                shouldCloseOnOverlayClick={false}
            >
                <div className='deFLex'>
                    { /* <button onClick={setCloseModalActiveCode} >
                        <FontAwesomeIcon icon={faArrowLeft} />
                    </button> */ }
                    <h4>Activar Cuenta</h4>
                </div>
                {activecode && (
                    <>
                        <div className='logo'>
                            <img src="/assets/images/logo.webp" alt="logo" />
                        </div>
                        <div className='AuthContainer'>
                            <div className='AuthTitle'>
                                <h2>Código de activación</h2>
                            </div>
                            <form id="frmActivarCuenta" className="formAuth">
                                <div className='formContain'>
                                    <div className='inputsAuth'>
                                        <label>Código de Activación</label>
                                        <input
                                            type="text"
                                            id="activecode"
                                            name="activecode"
                                            value={activecode}
                                            onChange={(e) => setActivecode(e.target.value)}
                                            placeholder='Código de Activación'
                                            readOnly={true}
                                        />
                                    </div>
                                    <div className='inputsAuth'>
                                        <button type="button" onClick={() => {validaUsuario()}} className='btn'>
                                            Activar Cuenta <FontAwesomeIcon icon={faCodeCompare} className='icon' />
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        { /*<div className='modal-content-detail'>
                            <div className='deFlex'>
                                <form id="frmActivarCuenta">
                                    <div className="modal-send-form">
                                        <button type="button" onClick={() => {validaUsuario()}} className='btn'>
                                            Acitvar Cuenta <FontAwesomeIcon icon={faCodeCompare} className='icon' />
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div> */ }
                    </>
                )}
            </Modal>
            { /* Modal Activar Cuenta */}

        </div>
    );
}